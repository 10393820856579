<template>
  <div class="yandex-maps-courier v-mb-sm">
    <div
      :id="uid"
      class="v-courier-map-container"
    />
  </div>
</template>

<script setup lang="ts">
import type { YMapMarker } from '@yandex/ymaps3-types'
import type { Terminal } from '~types/addressStore'
import type { YMapBounds } from '~types/mapsStore'

import { type GUID } from '@arora/common'

import { h, render } from 'vue'
import IconGeneralLocatorAddress from '~/icons/general/locatorAddress.vue'
import IconGeneralLocatorHome from '~/icons/general/locatorHome.vue'
import IconGeneralPeopleCourier from '~/icons/general/peopleCourier.vue'

const {
  addressLatitude,
  addressLongitude,
  courierLatitude,
  courierLongitude,
  deliveryTerminal
} = defineProps<{
  courierLongitude?: number
  courierLatitude?: number
  addressLatitude: number
  addressLongitude: number
  deliveryTerminal: GUID
}>()

const uid = `map-courier-${useId()}`
const courierMarkerId = `courier-${uid}`
const loaded = ref<boolean>(false)
const mapsStore = useMapsStore()
const addressStore = useAddressStore()
const appConfig = useAppConfig()
const terminalMarkers: Map<GUID, YMapMarker> = new Map()

const terminals = computed<Terminal[]>(() =>
  (addressStore.Terminals?.data ?? [])
    .filter((t) => t.Latitude !== 0 && t.Longitude !== 0)
    .filter(
      (t) =>
        appConfig.VueSettingsPreRun.ShowDisabledTerminals ||
        (t.Active && t.CanOrderSelfService)
    )
)

let lock = false
let bounds: YMapBounds = {
  maxLatitude: 0,
  maxLongitude: 0,
  minLatitude: 9999,
  minLongitude: 9999
}

let courierMarker: YMapMarker | null = null

const ymaps3Loaded = computed<boolean>(
  () => mapsStore.YandexMaps.state === 'success'
)

watch(
  [() => courierLongitude, () => courierLatitude],
  ([newLongitude, newLatitude], [oldLongitude, oldLatitude]) => {
    if (
      newLongitude &&
      newLatitude &&
      (newLongitude !== oldLongitude || newLatitude !== oldLatitude)
    ) {
      animateCourierMovement(
        [oldLongitude!, oldLatitude!],
        [newLongitude, newLatitude]
      )
    }
  }
)

function animateCourierMovement(from: [number, number], to: [number, number]): void {
  if (!courierMarker) return

  let startTime: number | null = null
  const duration = 50000

  const step = (timestamp: number): void => {
    if (!startTime) startTime = timestamp

    const progress = Math.min((timestamp - startTime) / duration, 1)

    const currentLng = from[0] + (to[0] - from[0]) * progress
    const currentLat = from[1] + (to[1] - from[1]) * progress

    if (courierMarker) {
      courierMarker.update({ coordinates: [currentLng, currentLat] })
    }

    if (progress < 1) {
      requestAnimationFrame(step)
    }
  }

  requestAnimationFrame(step)
}

watch(ymaps3Loaded, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await makeMap()
  }
})

async function makeMap(): Promise<void> {
  if (lock) return
  lock = true

  const markerElement = document.createElement('div')
  markerElement.id = `courier-marker-${uid}`
  markerElement.classList.add('courier-marker')

  courierMarker = new ymaps3.YMapMarker(
    {
      coordinates: [courierLongitude ?? 0, courierLatitude ?? 0],
      id: courierMarkerId
    },
    markerElement
  )
  const iconCourier = h(IconGeneralPeopleCourier)
  render(iconCourier, markerElement)

  for (const terminal of terminals.value) {
    const terminalMarkerElement = document.createElement('div')
    terminalMarkerElement.id = `terminal-marker-${terminal.ID}`
    terminalMarkerElement.classList.add('terminal-marker')

    const iconAddress = h(IconGeneralLocatorAddress)
    render(iconAddress, terminalMarkerElement)

    const terminalMarker = new ymaps3.YMapMarker(
      {
        coordinates: [terminal.Longitude, terminal.Latitude],
        id: `terminal-${terminal.ID}`
      },
      terminalMarkerElement
    )
    terminalMarkers.set(terminal.ID, terminalMarker)
  }

  if (addressLongitude && addressLatitude) {
    const addressMarkerElement = document.createElement('div')
    addressMarkerElement.id = `address-marker-${uid}`
    addressMarkerElement.classList.add('address-marker')

    const iconHome = h(IconGeneralLocatorHome)
    render(iconHome, addressMarkerElement)

    const markerAddress = new ymaps3.YMapMarker(
      {
        coordinates: [addressLongitude, addressLatitude],
        id: `address-${uid}`
      },
      addressMarkerElement
    )

    updateBounds()

    await mapsStore.makeYMap(uid, 'courier-map', bounds)

    mapsStore.addYMapsFeatures(uid, [courierMarker, markerAddress])

    if (terminalMarkers.has(deliveryTerminal)) {
      const selectedTerminalMarker = terminalMarkers.get(deliveryTerminal)!
      mapsStore.addYMapsFeatures(uid, [selectedTerminalMarker])
    }
  }
  loaded.value = true
  lock = false
}

function updateBounds(): void {
  if (addressLatitude && addressLongitude) {
    if (addressLatitude > bounds.maxLatitude) bounds.maxLatitude = addressLatitude
    if (addressLongitude > bounds.maxLongitude)
      bounds.maxLongitude = addressLongitude
  }
  for (const terminal of terminals.value) {
    if (terminal.Latitude < bounds.minLatitude)
      bounds.minLatitude = terminal.Latitude
    if (terminal.Longitude < bounds.minLongitude)
      bounds.minLongitude = terminal.Longitude
  }
}

onMounted(async () => {
  await (mapsStore.YandexMaps.state === 'success'
    ? makeMap()
    : mapsStore.initYandexMaps())
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-courier-map-container {
  height: 516px;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}

.courier-marker {
  width: 36px;
  height: 36px;
  background-color: variables.$PrimaryBackgroundColor;
  border-radius: 50%;
  padding: 8px 10px;
}
.address-marker {
  width: 49px;
  height: 64px;
  bottom: 0;
  position: absolute;
}
.terminal-marker {
  width: 49px;
  height: 64px;
  bottom: 0;
  position: absolute;
}
</style>
